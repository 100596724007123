.App {
  width: 50%;
  margin: 0 auto;
}

.code {
  font-family: monospace;
  background-color: #EEEEEE;
  padding: 2px;
  border-radius: 5px;
  margin: 10px;
}